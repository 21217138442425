import React from 'react';
import cx from 'classnames';
import Styles from "../../assets/styles/landingPage/getstarted.module.css"


const Getstarted = props => {
    return (
        <section className={cx(Styles.getstartedSection, 'text-center')}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx(Styles.readySectionContnt, 'col-12')}>
                        <h2 className={cx(Styles.sectionHeading)} dangerouslySetInnerHTML={{ __html: props.data.label_text1.value }}>
                            {/* Start here and see if you are eligible */}
                        </h2>
                        <div className={cx(Styles.getStarted)}>
                            <a className={cx(Styles.getStartedLink)} href="javascript://" onClick={(event) => {
                                props.handleChangeStep('application')
                            }} dangerouslySetInnerHTML={{ __html: props.data.label_button[0].elements.label.value }}>
                                {/* Get Started */}
                            </a>
                        </div>
                    </div>
                    <h4 className={cx('col-12', Styles.callus)}>
                        <a href="tel:1800 444 744" className={cx(Styles.contctText)} dangerouslySetInnerHTML={{ __html: props.data.label_text2prefix.value }}> 
                            {/* Or Call us on <strong>1800 444 744 </strong> */}
                        </a>
                    </h4>
                </div>
            </div>
        </section>
    );

}

export default Getstarted