import React from 'react';
import cx from 'classnames';
import logo from "../../../assets/images/logo.png";
import Styles from "../../../assets/styles/landingPage/footer.module.css"


const Footer = props => {
    return (
        <footer className={Styles.landingFooter}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx('col-xl-2 col-lg-3 col-md-3 col-sm-12', Styles.footerLeftside)}>
                        <a href="/">
                            <img className={cx('img-fluid')} src={logo} alt="HFG Marketplace home loans" />
                        </a>
                    </div>
                    <div className={cx('col-xl-10 col-lg-9 col-md-9 col-sm-12', Styles.footerRightside)}>
                        <p>{props.data.label__text1.value}</p>
                        <p dangerouslySetInnerHTML={{ __html: props.data.label__text2.value}}></p>
                    </div>
                </div>
            </div>
        </footer>
    );

}


export default Footer