import React from 'react';
import cx from 'classnames';
import Styles from "../../assets/styles/landingPage/whywait.module.css"


const Whywait = props => {
    return (
        <section className={cx(Styles.whywaitSection, 'text-center')}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx('col-12', Styles.headingsection)}>
                        <h2 className={cx(Styles.sectionHeading)} dangerouslySetInnerHTML={{ __html: props.data.label_text1.value }}></h2>
                        <div className={cx(Styles.sectionsumry)}>
                            <p dangerouslySetInnerHTML={{ __html: props.data.label_text2.value }}></p>
                            <p dangerouslySetInnerHTML={{ __html: props.data.label_text3.value }}></p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );

}

export default Whywait