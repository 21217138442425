import React from 'react';
// import "../../../assets/styles/googleFontsLato.css";
// import "../../../assets/fonts/lato.css";
import '../../../assets/styles/bootstrap.min.css';
import '../../../assets/styles/landingPage/landingLayout.module.css';
import ChatScript from "../../chatScript";
import CustomHelmet from '../../customHelmet'
import Utmtags from "../../utmtags";
import Header from "./header";
import Footer from "./footer";



const Layout = (props) => {
  // console.log(props)
  return (
    <div>
      {props.SEOData && (<CustomHelmet>
        <title>{props.SEOData.seo_title.value}</title>
        <meta name="description" content={props.SEOData.seo_description.value} />
        <meta name="keywords" content={props.SEOData.seo_keywords.value} />
        <link rel="canonical" href={props.SEOData.seo_canonicalurl.value} />
        <meta property="og:description" content={props.SEOData.seo_ogdescription.value} />
        <meta property="og:title" content={props.SEOData.seo_description.value} />
        <meta property="og:url" content={props.SEOData.seo_ogurl.value} />
        <meta property="og:image" content="https://www.hashching.com.au/assets/images/frontend/v2/logo-white.png" />
        <meta name="twitter:title" content={props.SEOData.seo_twittertitle.value} />
        <meta name="twitter:description" content={props.SEOData.seo_twitterdescription.value} />
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=17ac20c9-4f93-4b02-a610-5b7f897979d5"></script>
      </CustomHelmet>)}
      <Utmtags />
      <Header headerType={props.headerType} data={props.bannerData} />
      {props.children}
      {props.footer && (<Footer data={props.data} />)}
      {/* <ChatScript /> */}
    </div>
  );
} 

export default Layout;
