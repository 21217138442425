
import React from 'react';
import cx from 'classnames';
import Styles from "../../../assets/styles/landingPage/landingForm1.module.css"
import { log, oWindow, host } from '../../../helpers/commons'
// import Header from "./header";

class ApplicantForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            landingData: {
                educated: null,
                employed: null,
                citizen: null,
                couple: null,
                salary: null,
                groupIndividualSalary: null,
                groupCombinedSalary: null,
                groupInSameIndustry: null
            }
        }
        this.validateForm = this.validateForm.bind(this);
        this.onContinueClickHandler = this.onContinueClickHandler.bind(this);
    }

    showQualified() {
        let state = this.state;
        let landingData = state.landingData;

        if (landingData.educated == 'Yes' 
            && landingData.employed == 'Yes' 
            && landingData.citizen == 'Yes'
            && landingData.couple == 'No'
            && landingData.salary == 'Yes') {
            return true;
        }

        if (landingData.educated == 'Yes'
            && landingData.employed == 'Yes'
            && landingData.citizen == 'Yes'
            && landingData.couple == 'Yes'
            && landingData.groupCombinedSalary == 'gt180000'
            && (landingData.groupIndividualSalary == 'Yes')) {
            return true;
        }

        if (landingData.educated == 'Yes'
            && landingData.employed == 'Yes'
            && landingData.citizen == 'Yes'
            && landingData.couple == 'Yes'
            && landingData.groupCombinedSalary == 'gt180000'
            && landingData.groupInSameIndustry == 'Yes') {
            return true;
        }

        return false;
    }

    showDisQualifiedMessage() {

        let state = this.state;
        let landingData = state.landingData;


        if (landingData.educated == 'Yes' && landingData.employed == 'Yes' && landingData.citizen == 'No') {
            return this.props.data.label_message_type_2_text_2_citizen.value
        }

        if (landingData.educated == 'No' || landingData.employed == 'No' || landingData.citizen == 'No') {
            return this.props.data.label_message_type_2_text_2_educated.value
        }


        if (landingData.educated == 'Yes' && landingData.employed == 'Yes' && landingData.citizen == 'Yes') {
            return this.props.data.label_message_type_2_text_2_salary.value
        }
        
    }

    showDisQualified() {

        let state = this.state;
        let landingData = state.landingData;

        if(landingData.educated == 'No' || landingData.employed == 'No' || landingData.citizen == 'No') {
            return true;
        }

        if (landingData.educated == 'Yes' && landingData.employed == 'Yes' && landingData.citizen == 'Yes') {

            if (landingData.couple == 'No' && landingData.salary == 'No') {
                return true;
            }

            if(landingData.couple == 'Yes' && landingData.groupCombinedSalary == 'lt180000') {
                return true;
            }

            if (landingData.couple == 'Yes' && landingData.groupInSameIndustry == 'No') {
                return true;
            }
        }

        return false
    }

    onContinueClickHandler(event, btn) {
        console.log(arguments);
        let currentState = this.state;
        // for specific deal case where only those deals will no deposit amount
        if(btn == 'specific') {
            this.props.onGotoFunnel('specific', currentState.landingData);
        } else {
            this.props.onGotoFunnel('default', currentState.landingData);
        }
    }

    validateForm(event, validation = 'all', value = '') {

        log(validation)
        log(value);

        let landingData = this.state.landingData;

        let educated = landingData.educated

        let employed = landingData.employed;

        let citizen = landingData.citizen;

        let couple = landingData.couple;

        let salary = landingData.salary;

        let groupIndividualSalary = landingData.groupIndividualSalary;

        let groupCombinedSalary = landingData.groupCombinedSalary;

        let groupInSameIndustry = landingData.groupInSameIndustry;

        if (validation == 'educated') {
            let correctState = {};
            correctState.educated = value;
            if (this.state.landingData.educated != value) {
                correctState.employed = null;
                correctState.citizen = null;
                correctState.couple = null;
                correctState.salary = null;
                correctState.groupIndividualSalary = null;
                correctState.groupCombinedSalary = null;
            }

            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
            
        } else {  
            educated = landingData.educated;
        }

        if (validation == 'employed') {
            let correctState = {};
            correctState.employed = value;
            if (this.state.landingData.employed != value) {
                correctState.citizen = null;
                correctState.couple = null;
                correctState.salary = null;
                correctState.groupIndividualSalary = null;
                correctState.groupCombinedSalary = null;
            }
        
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            employed = landingData.employed;
        }

        if (validation == 'citizen') {
            let correctState = {};
            correctState.citizen = value;
            if (this.state.landingData.citizen != value) {    
                correctState.couple = null;
                correctState.salary = null;
                correctState.groupIndividualSalary = null;
                correctState.groupCombinedSalary = null;
            }
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })

        } else {
            citizen = landingData.citizen;
        }

        if (validation == 'couple') {

            let correctState = {};
            correctState.couple = value;
            if (this.state.landingData.couple != value) {
                correctState.salary = null;
                correctState.groupIndividualSalary = null;
                correctState.groupCombinedSalary = null;
                correctState.groupInSameIndustry = null;
            }
            
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            couple = landingData.couple;
        }

        if (validation == 'salary') {
            let correctState = {};
            correctState.salary = value;
            if (this.state.landingData.salary != value) {
                correctState.groupIndividualSalary = null;
                correctState.groupCombinedSalary = null;
                correctState.groupInSameIndustry = null;
            }
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            salary = landingData.salary;
        }

        if (validation == 'groupCombinedSalary') {
            let correctState = {};
            correctState.groupCombinedSalary = value;
            if (this.state.landingData.groupCombinedSalary != value) {
                correctState.groupIndividualSalary = null;
                correctState.groupInSameIndustry = null;
            }
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            groupCombinedSalary = landingData.groupCombinedSalary;
        }

        if (validation == 'groupIndividualSalary') {
            let correctState = {};
            correctState.groupIndividualSalary = value;
            if (this.state.landingData.groupIndividualSalary != value) {
                correctState.groupInSameIndustry = null;
            }
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            groupIndividualSalary = landingData.groupIndividualSalary;
        }

        if (validation == 'groupInSameIndustry') {
            let correctState = {};
            correctState.groupInSameIndustry = value;
            this.setState((state) => {
                return {
                    landingData: { ...state.landingData, ...correctState }
                }
            })
        } else {
            groupInSameIndustry = landingData.groupInSameIndustry;
        }
 
        if (!(!!value)) {

        } else {

        }
         
    }
    

    render() {

        let landingData = this.state.landingData;

        return (
            <section className={Styles.landingFormMain}>
                <div className={Styles.landingFormHeading}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-xl-9 col-lg-9 col-md-12', Styles.headingContnt)}>
                                <h1 className={Styles.heading} dangerouslySetInnerHTML={{ __html: this.props.data.label_heading_text1.value }}>
                                    {/* Check if you qualify, <br/>before you apply. */}
                                </h1>
                                <p className={Styles.subheading} dangerouslySetInnerHTML={{ __html: this.props.data.label_heading_text2.value }}>
                                    {/* Complete these simple questions <br/>below to find out */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.landingFormMain}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-xl-9 col-lg-9 col-md-12', Styles.landingFormContnt)}>
                                <form className={Styles.landingForm}>
                                    <h3 className={Styles.formHeading} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_heading_text.value }}>
                                        {/* Education and employment */}
                                    </h3>
                                    <div className={Styles.formFields}>
                                        <div className={cx(Styles.formGroup)}>
                                            <label className={Styles.fieldHeading} >
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text1.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                                <p className={Styles.subscript}>{this.props.data.label_form_field_subscript_text1.value}</p>
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                <div className={cx(Styles.btnGroup)} role="group">
                                                    <label className={cx(Styles.btn, (landingData.educated == 'Yes') ? Styles.active: '')}>Yes
                                                        <input type="radio" name="educated" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'educated', event.target.value);
                                                        })} />
                                                    </label>
                                                    <label className={cx(Styles.btn, (landingData.educated == 'No') ? Styles.active: '')}>No
                                                        <input type="radio" name="educated" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'educated', event.target.value);
                                                        })} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx(Styles.formGroup)}>
                                            <label className={Styles.fieldHeading}>
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text2.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                <div className={cx(Styles.btnGroup)} role="group">
                                                    <label className={cx(Styles.btn, (landingData.employed == 'Yes') ? Styles.active : '')}>Yes
                                                        <input type="radio" name="employed" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'employed', event.target.value);
                                                        })} />
                                                    </label>
                                                    <label className={cx(Styles.btn, (landingData.employed == 'No') ? Styles.active : '')}>No
                                                        <input type="radio" name="employed" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'employed', event.target.value);
                                                        })} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx(Styles.formGroup)}>
                                            <label className={Styles.fieldHeading} >   
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text3.value}}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                <div className={cx(Styles.btnGroup)} role="group">
                                                    <label className={cx(Styles.btn, (landingData.citizen == 'Yes') ? Styles.active : '')}>Yes
                                                        <input type="radio" name="citizen" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'citizen', event.target.value);
                                                        })} />
                                                    </label>
                                                    <label className={cx(Styles.btn, (landingData.citizen == 'No') ? Styles.active : '')}>No
                                                        <input type="radio" name="citizen" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'citizen', event.target.value);
                                                        })} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {landingData.educated == 'Yes' && landingData.employed == 'Yes' && landingData.citizen == 'Yes' && <div>
                                        <h3 className={Styles.formHeading} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_heading_text2.value }}>
                                            {/* Income */}
                                        </h3>
                                        <div className={Styles.formFields}>
                                        {<div className={cx(Styles.formGroup, Styles.leftpart)}>
                                            <label className={Styles.fieldHeading}>
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text4.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span> 
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                <div className={cx(Styles.btnGroup)} role="group">
                                                    <label className={cx(Styles.btn, (landingData.couple == 'Yes') ? Styles.active : '')}>Yes
                                                        <input type="radio" name="couple" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'couple', event.target.value);
                                                        })} />
                                                    </label>
                                                    <label className={cx(Styles.btn, (landingData.couple == 'No') ? Styles.active : '')}>No
                                                        <input type="radio" name="couple" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'couple', event.target.value);
                                                        })} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>}
                                        {landingData.couple == 'No' && (<div className={cx(Styles.formGroup, Styles.rightPart)}>
                                            <label className={Styles.fieldHeading}>
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text5.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                <div className={cx(Styles.btnGroup)} role="group">
                                                    <label className={cx(Styles.btn, (landingData.salary == 'Yes') ? Styles.active : '')}>Yes
                                                        <input type="radio" name="salary" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'salary', event.target.value);
                                                        })} />
                                                    </label>
                                                    <label className={cx(Styles.btn, (landingData.salary == 'No') ? Styles.active : '')}>No
                                                        <input type="radio" name="salary" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'salary', event.target.value);
                                                        })} />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>)}
                                        {landingData.couple == 'Yes' && <div className={cx(Styles.formGroup)}>
                                            <label className={Styles.fieldHeading}>
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text6.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                            </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupCombinedSalary == 'lt180000') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupCombinedSalary" value="lt180000" onClick={((event) => {
                                                        this.validateForm(event, 'groupCombinedSalary', event.target.value);
                                                    })} />
                                                     <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text6_option1.value }}></span>
                                                    {/* <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: 'Under $180,000' }}></span> */}
                                                </label>
                                            </div>
                                            {/* <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupCombinedSalary == 'btw200000-250000') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupCombinedSalary" value="btw200000-250000" onClick={((event) => {
                                                        this.validateForm(event, 'groupCombinedSalary', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text6_option2.value }}></span>
                                                </label>
                                            </div> */}
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupCombinedSalary == 'gt180000') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupCombinedSalary" value="gt180000" onClick={((event) => {
                                                        this.validateForm(event, 'groupCombinedSalary', event.target.value);
                                                    })} />
                                                     <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text6_option3.value }}></span> 
                                                    {/* <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: 'Over $180,000' }}></span> */}
                                                </label>
                                            </div>
                                        </div>}
                                        {landingData.couple == 'Yes' && landingData.groupCombinedSalary == 'gt180000' && <div className={cx(Styles.formGroup)}>
                                            <label className={Styles.fieldHeading}>
                                                <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text7.value }}></span>
                                                <span className={Styles.mandatorySign}> *</span>
                                            </label>
                                            {/* <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupIndividualSalary == 'Applicant1') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupIndividualSalary" value="Applicant1" onClick={((event) => {
                                                        this.validateForm(event, 'groupIndividualSalary', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text7_option1.value }}></span>
                                                </label>
                                            </div> */}
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupIndividualSalary == 'Yes') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                        <input type="radio" name="groupIndividualSalary" value="Yes" onClick={((event) => {
                                                        this.validateForm(event, 'groupIndividualSalary', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: 'Yes' }}></span>
                                                </label>
                                            </div>
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupIndividualSalary == 'No') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupIndividualSalary" value="No" onClick={((event) => {
                                                        this.validateForm(event, 'groupIndividualSalary', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)} dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text7_option3.value }}></span>
                                                </label>
                                            </div>
                                            {/* {landingData.groupIndividualSalary == 'No' && (<p className={cx(Styles.errorMsg)} dangerouslySetInnerHTML={{ __html: this.props.data.label_error_message.value }}>
                                            </p>)} */}
                                        </div>}

                                        {landingData.couple == 'Yes' && landingData.groupIndividualSalary == 'No' && <div className={cx(Styles.formGroup)}>
                                                <label className={Styles.fieldHeading}>
                                                    <span dangerouslySetInnerHTML={{ __html: this.props.data.label_form_field_text8.value }}></span>
                                                    {/* <span dangerouslySetInnerHTML={{ __html: 'Are you both applicants in a industry as stated?'}}></span> */}
                                                    <span className={Styles.mandatorySign}> *</span>
                                                </label>
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                        <span className={cx(Styles.radioCircle, (landingData.groupInSameIndustry == 'Yes') ? Styles.active : '')}>
                                                        <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                    <input type="radio" name="groupInSameIndustry" value="Yes" onClick={((event) => {
                                                            this.validateForm(event, 'groupInSameIndustry', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)}> Yes</span>
                                                </label>
                                            </div>
                                            <div className={cx(Styles.mobWidth, Styles.fieldRadiomark)}>
                                                <label className={cx(Styles.customRadiomark)}>
                                                    <span className={cx(Styles.radioCircle, (landingData.groupInSameIndustry == 'No') ? Styles.active : '')}>
                                                    <span className={cx(Styles.radiomark)}> </span>
                                                    </span>
                                                        <input type="radio" name="groupInSameIndustry" value="No" onClick={((event) => {
                                                            this.validateForm(event, 'groupInSameIndustry', event.target.value);
                                                    })} />
                                                    <span className={cx(Styles.readingText)}>No</span>
                                                </label>
                                            </div>
                                            <p className={Styles.subscript}><em>{this.props.data.label_form_field_text8_subscript.value}</em></p>
                                        </div>}
                                    </div>
                                    </div>}
                                    {this.showQualified() && <div className={cx(Styles.goodNewsSection, Styles.card)}>
                                        <h2 className={cx(Styles.heading)} dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_2_text_1.value }}>
                                            {/* Good news! */}
                                        </h2>
                                        <h4 dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_2_text_2.value }}>
                                            {/* Based on the information entered you could be eligible for up to <strong>$1,000,000</strong> */}
                                        </h4>
                                        <h5 dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_2_text_3.value }}>
                                            {/* Continue to complete your application and a home loan expert will get in touch with you. */}
                                        </h5>
                                        <a href="javascript://" className={Styles.continuelink} onClick={(event) => {
                                            this.onContinueClickHandler(event, 'specific');
                                        }} dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_2_text_4.value }}>
                                            {/* Continue */}
                                        </a>
                                    </div>}
                                    {this.showDisQualified() && <div className={cx(Styles.thankuSection, Styles.card)}>
                                        <h2 className={cx(Styles.heading)} dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_1_text_1.value }}>
                                            {/* Thank you for your interest */}
                                        </h2>
                                        <h4>{this.showDisQualifiedMessage()}</h4>
                                        <h5 dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_1_text_3.value }}>
                                            {/* If you would like to speak to a mortgage expert on how you can meet your goals, please continue and answer the following questions. */}
                                        </h5>
                                        <a href="javascript://" className={Styles.continuelink} onClick={(event) => {
                                            this.onContinueClickHandler(event, 'default');
                                        }} dangerouslySetInnerHTML={{ __html: this.props.data.label_message_type_1_text_4.value }}>
                                            {/* Continue */}
                                        </a>
                                    </div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ApplicantForm