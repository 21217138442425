import React from 'react';
import cx from 'classnames';
import MobLogo from "../../../assets/images/mob-logo.png";
import logoWhite from "../../../assets/images/logo-white.png";
import logo from "../../../assets/images/logo.png";
import Styles from "../../../assets/styles/landingPage/header.module.css"


const Header = props => {
    return (
        <header className={Styles.lendingHeader}>
            {props.headerType == 'transparent' && (<div className={Styles.lendingPageHeader}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-md-6 col-sm-12 col-12', Styles.logopart)}>
                            <a href="/">
                                <img className={cx('img-fluid', Styles.logoDsktop)} src={logoWhite} alt="HFG Marketplace home loans" />
                                <img className={cx('img-fluid', Styles.logoMob)} src={MobLogo} alt="HFG Marketplace home loans" />
                            </a>
                        </div>
                        <div className={cx('col-md-6 col-sm-12 col-12', Styles.headrContct)}>
                            <a href="tel:1800 444 744" className={Styles.contctText} dangerouslySetInnerHTML={{ __html: props.data.label_contact_number.value }}></a>
                        </div>
                    </div>
                </div>
            </div>)}
            {props.headerType == 'solid' && (<div className={Styles.lendingFormHeader}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-lg-2 col-md-3 col-sm-4 col-6', Styles.logopart)}>
                            <a href="/">
                                <img className={cx('img-fluid', Styles.logoDsktop)} src={logo} alt="HFG Marketplace home loans" />
                                <img className={cx('img-fluid', Styles.logoMob)} src={MobLogo} alt="HFG Marketplace home loans" />
                            </a>
                        </div>
                        <div className={cx('col-lg-10 col-md-9 col-sm-8 col-6', Styles.headrContct)}>
                            <a href="tel:1800 444 744" className={Styles.contctText} dangerouslySetInnerHTML={{ __html: props.data.label_contact_number.value }}></a>
                        </div>
                    </div>
                </div>
            </div>)}
        </header>
    );
}

export default Header