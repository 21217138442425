import React from 'react';
import cx from 'classnames';
import Styles from "../../assets/styles/landingPage/eligibility.module.css"


const Eligibility = props => {
    return (
        <section className={cx(Styles.eligibilitySection)}>
            <div className={'container'}>
                <div className={'row'}>
                    <h2 className={cx('col-md-12 col-12', Styles.sectionHeading)} dangerouslySetInnerHTML={{ __html: props.data.label_text.value }}>
                        {/* Requirements for Eligibility */}
                    </h2>
                    <div className={cx('col-xl-10 col-lg-11 col-md-12 col-sm-12 col-12 mr-auto ml-auto', Styles.eligibilityContnt)}>
                        <div className={'row'}>
                            <div className={cx('col-md-12 col-sm-12')}>
                                <ul className={cx(Styles.eligibilityList)}>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement1.value }}>
                                            {/* Must be an Australian citizen with <br/>clear credit. */}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement4.value }}>
                                            {/* 3+ years of placement within the <br/>professional industry. */}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement2.value }}>
                                            {/* Must be tertiary educated. */}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement5.value }}>
                                            {/* Properties must be owner occupied. */}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement3.value }}>
                                            {/* Yearly income must be $150,000+ <br/>($200,000 p.a when combined) */}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={Styles.listIcon}>

                                        </span>
                                        <span className={Styles.listTxt} dangerouslySetInnerHTML={{ __html: props.data.label_requirement6.value }}>
                                            {/* Maximum loan amount of $1million. */}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={cx('col-md-12 col-12 text-center', Styles.eligibilityBtn)}>
                        <a className={cx(Styles.btnLink)} href="javascript://" onClick={(event) => {
                            props.handleChangeStep('application')
                        }} dangerouslySetInnerHTML={{ __html: props.data.field_button_requirement[0].elements.label.value }}></a>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default Eligibility