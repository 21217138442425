import React from 'react';

class ChatScript extends React.Component {
  render()
  {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
      /*  window.__lc = window.__lc || {};
        window.__lc.license = 9926835;
        (function() {
          var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
          lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        })()
        */
      }, 5000);
    }
    return (
      <div className="chatscript"></div>
    )
  }
}
export default ChatScript;