import React from 'react';
import cx from 'classnames';
import Styles from "../../assets/styles/landingPage/banner.module.css"


const Banner = props => {
    return (
        <section className={Styles.bannerOverlay}>
            <div className={Styles.bannerSection}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-xl-7 col-lg-8 col-md-10 col-sm-12', Styles.bannercontntLeft)}>
                            <div className={Styles.bannerContnt}>
                                <h1 className={Styles.bannrHeading} >
                              
                                No Deposit? <span className={Styles.mobHeadSpace}>No Worries!</span> <br/>You Could Still <nobr>Get a Mortgage</nobr>
                                
                                
                                </h1>
                                <p className={Styles.bannerSumry} dangerouslySetInnerHTML={{ __html: props.data.label_description2.value }}></p>
                                <p className={Styles.seeBtn}>
                                    <a className={cx(Styles.seeBtnLink)} href="javascript://" onClick={(event) => {
                                        props.handleChangeStep('application')
                                    }} dangerouslySetInnerHTML={{ __html: props.data.button_qualify[0].elements.label.value }}></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default Banner