import React, { Fragment } from 'react';
import Layout from "../../components/landing/layout/layout";
import Banner from "../../components/landing/banner";
import Whywait from "../../components/landing/whywait";
import Eligibility from "../../components/landing/eligibility";
import Features from "../../components/landing/features";
import Getstarted from "../../components/landing/getstarted";
import Form from "../../components/landing/FormV1/form";
import cx from 'classnames';
import Axios from "axios";
import Cookies from 'universal-cookie';
import { graphql, navigate } from 'gatsby';
import LazyLoad from "react-lazy-load";
import HashchingReviews from '../../components/hashchingReviews';
import { log, oWindow, host, removeCookie} from '../../helpers/commons'
import allKenticoCloudItemPageLandingpagev1 from "../../../data/kentico/allKenticoCloudItemPageLandingpagev1";

class LandingPage extends React.Component {

    constructor(props) {        
        super(props);
        log(props, "props in the landing page")
        this.state = {
            steps: {
                current: 'get-started'
            },
            landingData: null,
            utmTags: {},
            referrerKey: '',
            flow: null, // to check for lead or broker flow
            stepsFollowed: []
        }

        this.handleClick = this.handleClick.bind(this);
        this.onGotoFunnel = this.onGotoFunnel.bind(this);
        removeCookie('broker');
        removeCookie('deal');
    }

    cookieSet(variable, value = '') {
        let cookies = new Cookies();
        cookies.set(variable, value, { path: '/' });
        return cookies.get(variable)
    }

    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

    onGotoFunnel(btn, data) {
        log("funnel goto step is clicked...")
        log(arguments);
        let currentState = this.state;
        if (typeof (Storage) !== "undefined") {
            currentState.landingData = data;
            currentState.flow = btn;
            currentState = JSON.stringify(currentState);
            sessionStorage.setItem("landingData", currentState);
            removeCookie('funnelData');
            removeCookie('landingData');
            if (!!this.state.utmTags.utm_campaign) {
              this.cookieSet('utm_campaign', this.state.utmTags.utm_campaign.split("#")[0]);
            }

            if (!!this.state.utmTags.utm_medium) {
              this.cookieSet('utm_medium', this.state.utmTags.utm_medium.split("#")[0]);
            }

            if (!!this.state.utmTags.utm_source) {
              this.cookieSet('utm_source', this.state.utmTags.utm_source.split("#")[0]);
            } 

            if (!!this.state.utmTags.utm_term) {
              this.cookieSet('utm_term', this.state.utmTags.utm_term.split("#")[0]);
            }
            
            if (!!this.state.utmTags.referrerId) {
              this.cookieSet('referrerId', this.state.utmTags.referrerId.split("#")[0]);
            }
            
          window.location.href = '/loan-enquiry/#/';
        }
    }

    handleClick(nextStep, data = null, gotToPrevStep = '') {
        log('handle click called...')
        log(nextStep);
        log(data);
        let nextStepReturned = this.validateSteps(nextStep, data);
        log('returned route ==> ' + nextStepReturned)
        if (nextStepReturned) {
            let steps = this.state.steps;
            let currentStep = this.state.steps.current;
            let prevSteps = this.state.stepsFollowed

            if (prevSteps.indexOf(currentStep) == -1) {
                if (currentStep == 'refinance' || currentStep == 'new-loan') {
                    prevSteps[1] = currentStep;
                } else if (currentStep == 'property-info') {
                    prevSteps[2] = currentStep;
                } else {
                    prevSteps.push(currentStep);
                }

            }

            if (prevSteps.indexOf(nextStepReturned) == -1) {
                if (nextStepReturned == 'refinance' || nextStepReturned == 'new-loan') {
                    prevSteps[1] = nextStepReturned;
                } else if (nextStepReturned == 'property-info') {
                    prevSteps[2] = nextStepReturned;
                } else {
                    prevSteps.push(nextStepReturned);
                }
            }

            this.setState({
                steps: { ...steps, current: nextStepReturned },
                stepsFollowed: prevSteps
            })

            this.handleChangeRoute(nextStepReturned)
        }

        log('change step is clicked....');
    }

    validateSteps(step, data = null) {
        log('validate step called..');
        log(step)
        log(data);
        if (step) {
            let state = this.state;
            let currentStep = state.steps.current;
            let landingData = state.landingData;
            let flow = this.cookieGet('flow');
            if (step == 'application') {
                log('====> application step');
                return step;
            }
        }
        return false;
    }

    handleChangeRoute(route) {
        let currentState = this.state;
        log(currentState, "current state in route change ===>")
        this.cookieSet('landingData', currentState)
        oWindow.write("landingInProgress", "true");
        log(route, "===> called change route");
        if (typeof window != 'undefined') {
            window.location.hash = '#/' + route
        }
    }

    componentDidMount() {
        // window.location.replace("/");
        log("component did mount called...")
        let params = {}, queries, temp, i, l, queryString;
        queryString = this.props.location.search;
        if (queryString.indexOf('?') != -1) {
            queryString = queryString.split("#")[0];
            queryString = decodeURIComponent(queryString.substring(queryString.indexOf('?') + 1));
            queries = queryString.split("&");
            for (i = 0, l = queries.length; i < l; i++) {
                temp = queries[i].split('=');
                if (temp.length > 2) {
                    params[temp[0]] = `${temp[1]}=`;
                } else {
                    params[temp[0]] = temp[1];
                }
            }
        }

      if (Object.keys(params).length != 0 && this.state.utmTags.length == 0) {
            this.cookieSet('referrerId', params.id);
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
              this.cookieSet('utm_campaign', params.utm_campaign);
              this.cookieSet('utm_medium', params.utm_medium);
              this.cookieSet('utm_source', params.utm_source);
              this.cookieSet('utm_term', params.utm_term);
            }
        }

        if (this.cookieGet('utm_campaign')) {
            params['utm_campaign'] = this.cookieGet('utm_campaign');
        }
        if (this.cookieGet('utm_medium')) {
            params['utm_medium'] = this.cookieGet('utm_medium');
        }
        if (this.cookieGet('utm_source')) {
            params['utm_source'] = this.cookieGet('utm_source');
        }
        if (this.cookieGet('utm_term')) {
            params['utm_term'] = this.cookieGet('utm_term');
        }
        if (this.cookieGet('referrerId')) {
            params['referrerId'] = this.cookieGet('referrerId');
        }

        if (this.props.location.href) {
            this.setState({
                pageSource: this.props.location.origin + '/access-no-deposit-home-loan-australia/'
            });
        }
        
        if (Object.keys(params).length != 0) {
            if (params.referrerId) {
                this.setState({
                    referrerKey: params.referrerId
                });
            }
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
                this.setState({
                    utmTags: params
                });
            }
        }
    }

    componentWillMount() {
        let urlParts = [];
        let slug = '';

        if (typeof document != 'undefined') {
            log(window.location.hash, "window hash");
            slug = window.location.hash.replace('#/', '');
        }


        let validSlugs = ['application']

        if (!!slug) {

            if (validSlugs.indexOf(slug) == -1) {
                navigate('/access-no-deposit-home-loan-australia')
            }
            //if is not not first step
            if (this.cookieGet('landingData') == undefined) {
                navigate('/access-no-deposit-home-loan-australia')
            }
        }

        if (!slug) {
            if (oWindow.landingProgress == 'undefined') {
                removeCookie('landingData');
            }
            log("at the first step.");
        }

        log("component will mount called...")

        let cookeState = this.cookieGet('landingData');

        if (!!cookeState && cookeState != undefined) {

            let stepsFollowed = cookeState.stepsFollowed;

            log(stepsFollowed);

            if (!!slug && stepsFollowed.indexOf(slug) == -1) {
                log("at the wrong place");
                navigate('/access-no-deposit-home-loan-australia')
            }

            if (!!slug && cookeState.steps.current != slug) {
                cookeState.steps.current = slug
            }
        }

        if (cookeState != undefined) {
            log('inside set state')
            this.setState(cookeState);
        } else {
            this.cookieSet('landingData', this.state)
            log(this.state, 'will mount current state==>');
        }
    }

    onStateChange(slug) {
        log("on state change called..");

        if (!!slug) {
            log(slug);
            if (this.state.steps.current != slug) {
                this.setState({
                    steps: { ...this.state.steps, current: slug }
                })
            }
        }

        this.cookieSet('landingData', this.state);
    }

    render() {
        log("I'm in render")

        let slug = '';

        //const node = this.props.data.allKenticoCloudItemPageLandingpagev1.edges[0].node;
        const node = allKenticoCloudItemPageLandingpagev1.edges[0].node;
        
        log(node.elements, "kentico data")

        let hashching_reviews = node.elements.facebook_reviews[0].elements;

        let heroBannerData = node.elements.hero_banner[0].elements;

        let heroBannerDescriptionData = node.elements.hero_banner_description[0].elements;

        let requirementsSection = node.elements.requirements_section[0].elements;

        let featuresAndBenefites = node.elements.features_and_benefites[0].elements;
        
        let getStarted = node.elements.get_started[0].elements;

        let footerSection = node.elements.footer_section[0].elements;

        let landingFormData = node.elements.landing_form_v1[0].elements

        let SEOData = {};

        SEOData.seo_canonicalurl = node.elements.section___seo_content_508c18c__seo_canonicalurl

        SEOData.seo_description = node.elements.section___seo_content_508c18c__seo_description

        SEOData.seo_keywords = node.elements.section___seo_content_508c18c__seo_keywords
        
        SEOData.seo_ogdescription = node.elements.section___seo_content_508c18c__seo_ogdescription

        SEOData.seo_ogkeywords = node.elements.section___seo_content_508c18c__seo_ogkeywords

        SEOData.seo_ogtitle = node.elements.section___seo_content_508c18c__seo_ogtitle

        SEOData.seo_ogurl = node.elements.section___seo_content_508c18c__seo_ogurl

        SEOData.seo_title = node.elements.section___seo_content_508c18c__seo_title

        SEOData.seo_twitterdescription = node.elements.section___seo_content_508c18c__seo_twitterdescription

        SEOData.seo_twitterkeywords = node.elements.section___seo_content_508c18c__seo_twitterkeywords

        SEOData.seo_twittertitle = node.elements.section___seo_content_508c18c__seo_twittertitle

        SEOData.seo_twitterurl = node.elements.section___seo_content_508c18c__seo_twitterurl
        

        if (typeof window != 'undefined') {
            slug = window.location.hash.replace('#/', '');

            if (!!slug) {
                log(this.state.stepsFollowed.indexOf(slug), 'render direct access');
                if (this.state.stepsFollowed.indexOf(slug) == -1) {
                    window.location.hash = '#/';
                }
            }
        }

        this.onStateChange(slug);

        let AllProps = {
            handleChangeStep: this.handleClick,
            currentState: this.state,
            backBtnHandler: this.backBtnHandler,
            landingData: this.state.landingData,
            onGotoFunnel: this.onGotoFunnel
        };

        let view = () => (
          <Layout headerType={'solid'} footer={false} data={footerSection} SEOData={SEOData} bannerData={heroBannerData}>{null}</Layout>
        );
        

        if (typeof window !== `undefined`) {

            const HashRouter = require("react-router-dom");

            let Router = HashRouter.HashRouter;

            let Route = HashRouter.Route;

            view = () => (
                <Router>
                    <section>
                        <Route exact path="/" component={() => {
                            return (
                              <Layout headerType={'transparent'} footer={true} data={footerSection} SEOData={SEOData} bannerData={heroBannerData}>
                                    <Fragment>
                                        <Banner {...AllProps} data={heroBannerData} />
                                        <Whywait {...AllProps} data={heroBannerDescriptionData} />
                                        <Eligibility {...AllProps} data={requirementsSection} />
                                        <Features {...AllProps} data={featuresAndBenefites} />
                                        <LazyLoad offsetVertical={400}>
                                        <HashchingReviews content={hashching_reviews} customClass ={true} />
                                        </LazyLoad>
                                        <Getstarted {...AllProps} data={getStarted} />
                                    </Fragment>
                                </Layout>
                            )
                        }} />

                        <Route exact path="/application" component={() => {
                            return (
                              <Layout headerType={'solid'} footer={false} data={footerSection} SEOData={SEOData} bannerData={heroBannerData}> 
                                    <Fragment>
                                        <Form {...AllProps} data={landingFormData} />
                                    </Fragment>
                                </Layout>
                            )
                        }
                        } />
                    </section>
                </Router>
            )
        }

        return (
            <div>{view()}</div>
        )
    }
}

// export const query = graphql`
// {
//  allKenticoCloudItemPageLandingpagev1(filter: { system: { language: { eq: "en-AU" }}})
//   {
//     edges {
//       node {
//         elements {
//           hero_banner {
//             elements {
//               label_contact_number {
//                 value
//               }
//               label_description1 {
//                 value
//               }
//               label_description2 {
//                 value
//               }
//               button_qualify {
//                 elements {
//                   label {
//                     value
//                   }
//                 }
//               }
//             }
//           }
//           hero_banner_description {
//             elements {
//               label_text1 {
//                 value
//               }
//               label_text2 {
//                 value
//               }
//               label_text3 {
//                 value
//               }
//             }
//           }
//           requirements_section {
//             elements {
//               label_text {
//                 value
//               }
//               label_requirement1 {
//                 value
//               }
//               label_requirement2 {
//                 value
//               }
//               label_requirement3 {
//                 value
//               }
              
//               label_requirement4 {
//                 value
//               }
              
//               label_requirement5 {
//                 value
//               }
//               label_requirement6 {
//                 value
//               }
//               field_button_requirement {
//                 elements {
//                   label {
//                     value
//                   }
//                 }
//               }
//             }
//           }
//           features_and_benefites {
//             elements {
//               label_text1 {
//                 value
//               }
//               label_feature_text1 {
//                 value
//               }
//               label_feature_text2 {
//                 value
//               }
//               label_feature_text3 {
//                  value
//               }
              
//               label_feature_text4 {
//                  value
//               }
//               label_feature_text5 {
//                 value
//               }
//               label_feature_text6 {
//                 value
//               }
//               label_feature_text7 {
//                 value
//               }
//               label_feature_text8 {
//                 value
//               }
//               label_feature_text9 {
//                 value
//               }
//               label_feature_text10 {
//                 value
//               }
//             }
//           }
//           facebook_reviews {
//             elements{
//               label_heading {
//                 value
//               }
//               label_description {
//                 value
//               }
//             }
//           }
//           get_started {
//             elements {
//               label_text1 {
//                 value
//               }
//               label_text2prefix {
//                 value
//               }
              
//               label_button {
//                 elements {
//                   label {
//                     value
//                   }
//                 }
//               }
//             }
//           }
//           footer_section {
//             elements {
//               label__text1 {
//                 value
//               }
//               label__text2 {
//                 value
//               }
//             }
//           }
//           section___seo_content_508c18c__seo_title {
//             value
//           }
//           section___seo_content_508c18c__seo_keywords {
//             value
//           }
          
//           section___seo_content_508c18c__seo_description {
//             value
//           }
//           section___seo_content_508c18c__seo_canonicalurl {
//             value
//           }
//           section___seo_content_508c18c__seo_ogtitle {
//             value
//           }
//           section___seo_content_508c18c__seo_ogdescription{
//             value
//           }
//           section___seo_content_508c18c__seo_ogkeywords {
//             value
//           }
//           section___seo_content_508c18c__seo_ogurl {
//             value
//           }
//           section___seo_content_508c18c__seo_twittertitle  {
//             value
//           }
//           section___seo_content_508c18c__seo_twitterdescription {
//             value
//           }
//           section___seo_content_508c18c__seo_twitterkeywords {
//             value
//           }
//           section___seo_content_508c18c__seo_twitterurl {
//             value
//           }
//           landing_form_v1 {
//             elements{
//               label_heading_text1 {
//                 value
//               }
//               label_heading_text2 {
//                 value
//               }
//               label_form_heading_text {
//                 value
//               }
//               label_form_field_text1 {
//                 value
//               }
//               label_form_field_subscript_text1{
//                 value
//               }
//               label_form_field_text2 {
//                 value
//               }
//               label_form_field_text3 {
//                 value
//               }
//               label_form_heading_text2 {
//                 value
//               }
//               label_form_field_text4 {
//                 value
//               }
//               label_form_field_text5 {
//                 value
//               }
//               label_form_field_text6 {
//                 value
//               }
//               label_form_field_text6_option1 {
//                 value
//               }
//               label_form_field_text6_option2 {
//                 value
//               }
//               label_form_field_text6_option3 {
//                 value
//               }
//               label_form_field_text7 {
//                 value
//               }
//               label_form_field_text7_option1 {
//                 value
//               }
//               label_form_field_text7_option2 {
//                 value
//               }
//               label_form_field_text7_option3 {
//                 value
//               }
//               label_form_field_text8 {
//                 value
//               }
//               label_form_field_text8_option1 {
//                 value
//               }
//               label_form_field_text8_option2{
//                 value
//               }
//               label_form_field_text8_subscript{
//                 value
//               }
//               label_message_type_2_text_1 {
//                 value
//               }
//               label_message_type_2_text_2 {
//                 value
//               }
//               label_message_type_2_text_2_educated {
//                 value
//               }
              
//               label_message_type_2_text_2_citizen {
//                 value
//               }
              
//               label_message_type_2_text_2_salary {
//                 value
//               }
//               label_message_type_2_text_3 {
//                 value
//               }
//               label_message_type_2_text_4 {
//                 value
//               }
//               label_message_type_1_text_1 {
//                 value
//               }
//               label_message_type_1_text_2 {
//                 value
//               }
//               label_message_type_1_text_3 {
//                 value
//               }
//               label_message_type_1_text_4 {
//                 value
//               }
//               label_error_message {
//                 value
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
// `;

export default LandingPage