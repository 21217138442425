import React from 'react';
import cx from 'classnames';
import Styles from "../../assets/styles/landingPage/features.module.css"
import featureImg from "../../assets/images/landing-page/landingFeatureImg.png";


const Features = props => {
    return (
        <section className={cx(Styles.featuresSection)}>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={cx('col-lg-6 col-md-12', Styles.leftSection)}>
                        <img className={cx('img-fluid', Styles.featureImg)} src={featureImg} alt="feature-Img" />
                    </div>
                    <div className={cx('col-lg-6 col-md-12', Styles.rightSection)}>
                        <h2 className={cx(Styles.sectionHeading)} dangerouslySetInnerHTML={{ __html: props.data.label_text1.value }}>
                            {/* Features and benefits */}
                        </h2>
                        <ul className={cx(Styles.sectionListing)}>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text1.value }}>
                                    {/* Low annual fee to save you money */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text2.value }}>
                                    {/* Accelerated loan repayments to reduce loan amount faster and <br />applicable interest rate */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text3.value }}>
                                    {/* Available for Purchase of Owner Occupied house or unit */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text4.value }}>
                                    {/* Pay your loan off sooner with 100% Offset available */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text5.value }}>
                                    {/* When you have some extra cash, Unlimited extra payments */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text6.value }}>
                                    {/* Access to your money when you need it with Free online redraw */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text7.value }}>
                                    {/* Payment cycle to suit you with Monthly, Fortnightly or Weekly repayments */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text9.value }}>
                                    {/* Greater Flexibility with Direct Debit and Direct Credit Facilities */}
                                </span>
                            </li>
                            <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text10.value }}>
                                    {/* Attached VISA debit card. */}
                                </span>
                            </li>
                             <li>
                                <span dangerouslySetInnerHTML={{ __html: props.data.label_feature_text8.value }}>
                                    {/* Attached VISA debit card. */}
                                </span>
                            </li>
                        </ul>
                    </div>



                </div>
            </div>
        </section>

    );

}

export default Features